import React, { useState, useEffect, useRef, useContext } from 'react';
import { gql } from "@apollo/client";
import { useNavigate } from 'react-router-dom'
import { ToastComponent } from '@syncfusion/ej2-react-notifications';
import { PivotViewComponent, Inject, FieldList, CalculatedField, Toolbar, PDFExport, ExcelExport, ConditionalFormatting, NumberFormatting } from '@syncfusion/ej2-react-pivotview';
import { getTaskStatus, GET_SCREENS_PANELS_ACTIONS } from '../../apollo/queries';
import { updatePanelsDefinition } from '../../apollo/mutations';
import { SYS_UPDATE_PANEL_VIEW_PIVOT, SYS_ADD_PANEL_VIEW_PIVOT, SYS_DELETE_PANELS_VIEW, RENAME_SYS_PANELS_VIEW } from '../../apollo/tasks';
import { useApolloClient } from '@apollo/react-hooks';
import { SidebarContext } from './Main/context';
import { useDispatch, useSelector } from 'react-redux';
import { Player } from '@lottiefiles/react-lottie-player';


const PivotToolbar = ({ panel, panelJSON, pivotsQuery, queryName, dynamicGridHeight }) => {
    const toastRef = React.useRef(null);
    const dispatch = useDispatch();
    const client = useApolloClient();
    const [dogsVaccinations, setDogsVaccinations] = useState();
    const navigate = useNavigate();
    let pivotObj = useRef(null);
    const [dataSourceSettings, setDataSourceSettings] = useState({});
    const [displayOption, setDisplayOption] = useState({})
    const [chartSettings, setChartSettings] = useState({})
    const [isLoading, setIsLoading] = useState(false);
    const sidebarContext = useContext(SidebarContext);
    const isManageEditButtonCLicked = useSelector(state => state.manageViewsReducer.isManageEditButtonCLicked);
    const isManageSaveButtonCLicked = useSelector(state => state.manageViewsReducer.isManageSaveButtonCLicked);
    const isManageDeleteButtonClicked = useSelector(state => state.manageViewsReducer.isManageDeleteButtonClicked);
    const isManageVewsSaveAsDialogSaveButtonClicked = useSelector(state => state.manageViewsReducer.isManageVewsSaveAsDialogSaveButtonClicked);
    const isManageVewsRenameDialogSaveButtonClicked = useSelector(state => state.manageViewsReducer.isManageVewsRenameDialogSaveButtonClicked);
    const manageViewsPanelsGuid = useSelector(state => state.manageViewsReducer.manageViewsPanelsGuid);
    const panelsViewGuid = useSelector((state) => state.mainReducer.panelsViewGuid);
    const manageViewsViewName = useSelector((state) => state.manageViewsReducer.manageViewsViewName);
    const panelStateJSON = useSelector(state => state.mainReducer.panelStateJSON);
    const refreshedPanelsGuid = useSelector(state => state.mainReducer.refreshedPanelsGuid);
    const isRefreshButtonClicked = useSelector(state => state.mainReducer.isRefreshButtonClicked);
    const screenRefreshTime = useSelector(state => state.mainReducer.screenRefreshTime);
    const argsItemValue = useSelector(state => state.mainReducer.argsItemValue);



    const _getPivotsQuery = async () => {
        setIsLoading(true)
        const { data } = await client.query({ query: gql`${pivotsQuery}` })
        const responseData = data[queryName]
        setDogsVaccinations(responseData)
        if (panelJSON.pivot_definition === null) {
            setDataSourceSettings({
                dataSource: responseData,
            })
            setDisplayOption({
                view: 'Both',
            })
            setChartSettings({
                title: 'Sample',
            })
            setIsLoading(false)
        }
        else {
            let dataSourceSettingsCopy = JSON.parse(JSON.stringify(panelJSON.pivot_definition.pivot.report))
            dataSourceSettingsCopy.dataSourceSettings.dataSource = responseData
            setDataSourceSettings(dataSourceSettingsCopy.dataSourceSettings)
            setDisplayOption(dataSourceSettingsCopy.displayOption)
            setChartSettings(dataSourceSettingsCopy.chartSettings)
            setIsLoading(false)
        }

    }



    useEffect(() => {
        if (panel.sys_panels_guid === refreshedPanelsGuid) {
            _getPivotsQuery()
        }
        return () => {
            dispatch({ type: 'TOGGLE_MANAGE_VIEWS_EDIT_BUTTON', payload: false })
        }
    }, [isRefreshButtonClicked])

    useEffect(() => {
        let interval;
        if (panel.auto_refresh) {
            interval = setInterval(() => _getPivotsQuery(), 1000 * screenRefreshTime)
        }
        _getPivotsQuery()
        return () => {
            clearInterval(interval)
        };
    }, [panel, panelJSON,])


    useEffect(() => {
        if ((isManageSaveButtonCLicked && manageViewsPanelsGuid === panel.sys_panels_guid) && panelStateJSON.element_type !== 'map') {
            saveReport()
        }
        return () => {
            return dispatch({ type: 'TOGGLE_MANAGE_VIEWS_SAVE_BUTTON', payload: false })
        }

    }, [isManageSaveButtonCLicked])

    useEffect(() => {

        if ((isManageDeleteButtonClicked && manageViewsPanelsGuid === panel.sys_panels_guid) && panelStateJSON.element_type !== 'map') {
            deleteReport()
        }
        return () => {
            return dispatch({ type: 'CLICK_MANAGE_VIEWS_DELETE_BUTTON', payload: false })
        }

    }, [isManageDeleteButtonClicked])

    useEffect(() => {
        if ((isManageVewsSaveAsDialogSaveButtonClicked && manageViewsPanelsGuid === panel.sys_panels_guid) && panelStateJSON.element_type !== 'map') {
            saveAsReport()
        }
        return () => {
            return dispatch({ type: 'CLICK_MANAGE_VIEWS_SAVE_AS_DIALOG_SAVE_BUTTON', payload: false })
        }

    }, [isManageVewsSaveAsDialogSaveButtonClicked])

    useEffect(() => {
        if ((isManageVewsRenameDialogSaveButtonClicked && manageViewsPanelsGuid === panel.sys_panels_guid) && panelStateJSON.element_type !== 'map') {
            renameReport()
        }
        return () => {
            return dispatch({ type: 'CLICK_MANAGE_VIEWS_RENAME_DIALOG_SAVE_BUTTON', payload: false })
        }

    }, [isManageVewsRenameDialogSaveButtonClicked])

    const toolbarOptions = ['Grid', 'Chart', 'Export', 'SubTotal', 'GrandTotal', 'Formatting', 'FieldList']

    const showToast = (message, type) => {
        if (toastRef.current) {
            toastRef.current.show({
                content: message || '',
                cssClass: `e-toast-${type} custom-toast-position`,
                position: { X: 'Right', YOffset: '1000' },
                animation: { show: { duration: 500 }, hide: { duration: 500 } },
                timeOut: 3000,
            });
        }

    }


    const deleteReport = async () => {
        const { data } = await client.query({
            query: SYS_DELETE_PANELS_VIEW,
            variables: {
                v_locale: "en",
                v_sys_panels_views_guid: panelsViewGuid || argsItemValue,
                v_sys_owners_guid: "43f2b87a-2946-4a8c-bdbc-78c82341d2ab"
            }
        })
        if (data.dal_add_task) {
            const status = await client.query({ query: getTaskStatus, variables: { tasks_guid: data.dal_add_task[0].gql_results.tasks_guid } })
            if (status?.data?.dal_get_task_status[0].return_code === 'SUCSS') {
                showToast(status?.data?.dal_get_task_status[0].return_code_locale.message, 'success');
            }
            else {
                showToast(status?.data?.dal_get_task_status[0].return_code_locale.message, 'danger');
            }
        }
    }


    const saveReport = async () => {
        let panelsDefinition = JSON.parse(JSON.stringify(panelJSON.pivot_definition))
        let reportCopy = JSON.parse(pivotObj.current.getPersistData())
        let panelDefWithToolbar = { showtoolbar: false }
        reportCopy.dataSourceSettings.dataSource = queryName
        panelsDefinition.report = reportCopy
        delete panelsDefinition.pivot
        panelDefWithToolbar.pivot = panelsDefinition
        const { data } = await client.query({
            query: SYS_UPDATE_PANEL_VIEW_PIVOT,
            variables: {
                locale: "en",
                v_sys_panels_views_guid: panelsViewGuid || argsItemValue,
                v_pivot_json_definition: JSON.parse(JSON.stringify(panelDefWithToolbar))
            }
        })
        if (data.dal_add_task) {
            const status = await client.query({ query: getTaskStatus, variables: { tasks_guid: data.dal_add_task[0].gql_results.tasks_guid } })
            if (status?.data?.dal_get_task_status[0].return_code === 'SUCSS') {
                showToast(status?.data?.dal_get_task_status[0].return_code_locale.message, 'success');
            }
            else {
                showToast(status?.data?.dal_get_task_status[0].return_code_locale.message, 'danger');
            }
        }
    }

    const renameReport = async () => {
        const { data } = await client.query({
            query: RENAME_SYS_PANELS_VIEW,
            variables: {
                v_locale: "en",
                v_sys_panels_views_guid: panelsViewGuid || argsItemValue,
                v_sys_owners_guid: "43f2b87a-2946-4a8c-bdbc-78c82341d2ab",
                v_view_name: manageViewsViewName,
            }
        })
        if (data.dal_add_task) {
            const status = await client.query({ query: getTaskStatus, variables: { tasks_guid: data.dal_add_task[0].gql_results.tasks_guid } })
            if (status?.data?.dal_get_task_status[0].return_code === 'SUCSS') {
                showToast(status?.data?.dal_get_task_status[0].return_code_locale.message, 'success');
            }
            else {
                showToast(status?.data?.dal_get_task_status[0].return_code_locale.message, 'danger');
            }
        }
    }

    const saveAsReport = async () => {
        let panelsDefinition = JSON.parse(JSON.stringify(panelJSON.pivot_definition))
        let reportCopy = JSON.parse(pivotObj.current.getPersistData())
        let panelDefWithToolbar = { showtoolbar: false }
        reportCopy.dataSourceSettings.dataSource = queryName
        panelsDefinition.report = reportCopy
        delete panelsDefinition.pivot
        panelDefWithToolbar.pivot = panelsDefinition
        const { data } = await client.query({
            query: SYS_ADD_PANEL_VIEW_PIVOT,
            variables: {
                locale: "en",
                v_sys_panels_guid: panel.sys_panels_guid,
                v_sys_owners_guid: "43f2b87a-2946-4a8c-bdbc-78c82341d2ab",
                v_view_name: manageViewsViewName,
                v_pivot_json_definition: JSON.parse(JSON.stringify(panelDefWithToolbar))
            }
        })
        if (data.dal_add_task) {
            const status = await client.query({ query: getTaskStatus, variables: { tasks_guid: data.dal_add_task[0].gql_results.tasks_guid } })
            if (status?.data?.dal_get_task_status[0].return_code === 'SUCSS') {
                showToast(status?.data?.dal_get_task_status[0].return_code_locale.message, 'success');
            }
            else {
                showToast(status?.data?.dal_get_task_status[0].return_code_locale.message, 'danger');
            }
        }
    }


    function fetchReport(args) {
        let reportCollection = [];
        let reeportList = [];
        if (panelJSON.pivot_definition.pivot !== null) {
            reportCollection.push(panelJSON.pivot_definition.pivot)
        }
        reportCollection.map(function (item) { reeportList.push(item.reportName); });
        args.reportName = reeportList;
        args = panelJSON.pivot_definition
    }
    function loadReport(args) {
        setDataSourceSettings(JSON.parse(panelJSON.pivot_definition.pivot.report).dataSourceSettings)
        let reportCollection = [];
        pivotObj.dataSourceSettings = JSON.parse(panelJSON.pivot_definition.report).dataSourceSettings

        if (localStorage.pivotviewReports && localStorage.pivotviewReports !== "") {
            reportCollection = JSON.parse(localStorage.pivotviewReports);
        }
        reportCollection.map(function (item) {
            if (args.reportName === item.reportName) {
                args.report = item.report;
            }
        });
        if (args.report) {
            pivotObj.dataSourceSettings = JSON.parse(args.report).dataSourceSettings;
        }
    }
    function removeReport(args) {
        let reportCollection = [];
        if (localStorage.pivotviewReports && localStorage.pivotviewReports !== "") {
            reportCollection = JSON.parse(localStorage.pivotviewReports);
        }
        for (let i = 0; i < reportCollection.length; i++) {
            if (reportCollection[i].reportName === args.reportName) {
                reportCollection.splice(i, 1);
            }
        }
        if (localStorage.pivotviewReports && localStorage.pivotviewReports !== "") {
            localStorage.pivotviewReports = JSON.stringify(reportCollection);
        }
    }

    function newReport(args) {
        console.log(args)
    }
    const beforeToolbarRender = (args) => {
        args.customToolbar.splice(6, 0, {
            type: 'Separator'
        });
        args.customToolbar.splice(9, 0, {
            type: 'Separator'
        });
    }

    return (
        <div className="pivots">
            <ToastComponent ref={toastRef} />
            {
                isLoading ?
                    <div style={{ height: `${panel.panel_layout_json?.sizeY * (dynamicGridHeight + 4) || 12}px `, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Player
                            autoplay
                            loop
                            src="https://assets7.lottiefiles.com/packages/lf20_2svadkl0.json"
                            style={{ height: '20vh', width: '20vw' }}
                        />
                    </div>
                    :
                    <PivotViewComponent
                        height={isManageEditButtonCLicked && manageViewsPanelsGuid === panel.sys_panels_guid ? `${panel.panel_layout_json?.sizeY * (dynamicGridHeight + 6.2) || 12}px ` : `${panel.panel_layout_json?.sizeY * (dynamicGridHeight + 9.5) || 12}px `}
                        width="100%"
                        ref={pivotObj}
                        dataSourceSettings={dataSourceSettings}
                        showFieldList={isManageEditButtonCLicked && manageViewsPanelsGuid === panel.sys_panels_guid}
                        gridSettings={{ columnWidth: 140 }}
                        allowExcelExport={true}
                        allowNumberFormatting={true}
                        allowConditionalFormatting={true}
                        allowPdfExport={true}
                        showToolbar={true}
                        allowCalculatedField={true}
                        displayOption={displayOption}
                        toolbar={isManageEditButtonCLicked && manageViewsPanelsGuid === panel.sys_panels_guid ? toolbarOptions : []}
                        newReport={newReport}
                        removeReport={removeReport}
                        loadReport={loadReport}
                        fetchReport={fetchReport}
                        toolbarRender={beforeToolbarRender}
                        chartSettings={chartSettings}
                    >
                        <Inject services={[FieldList, CalculatedField, Toolbar, PDFExport, ExcelExport, ConditionalFormatting, NumberFormatting]} />

                    </PivotViewComponent>
            }
        </div>
    )
}

export default PivotToolbar



